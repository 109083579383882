<style lang="scss" scoped>
@import "@/styles/_colors.scss";
.header-wrapper:not(.biote) .my-profile {
  background-color: $light-medium-blue;
  .name {
    color: $light-grey;
  }
  &.is-disabled {
    background: $light-medium-blue !important;
  }
}
.header-wrapper.biote .my-profile {
  background-color: $biote--header-bg;
  .name {
    color: $biote--light-gray-color;
  }
  &.is-disabled {
    background: $biote--header-bg !important;
  }
}
.p-left-0 {
  padding-left: 0 !important;
}
.my-profile {
  width: auto;
  padding: 10px 5px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;

  &.is-disabled {
    opacity: 0.5;
    .header__link {
      cursor: not-allowed;
    }
  }

  &:hover {
    background: #556274;
  }

  .ant-select {
    width: auto !important;
  }
  .user-details {
    display: flex;
    width: 100%;
  }
  .ant-avatar {
    height: 18px;
    width: 18px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.vendor-details,
.org-details {
  padding: 10px;
  background: #f0f8ff69;
  cursor: default;
  line-height: 13px;

  .vendor-name,
  .org-name {
    font-weight: 700;
  }

  .org-name {
    padding-bottom: 10px;
  }
}

.org-details {
  .location-details {
    line-height: 12px;
    display: flex;
    justify-content: space-between;

    .ant-badge {
      width: 10px;
    }

    .col-1 {
      width: 57%;
    }

    .col-2 {
      padding-left: 10px;
      width: 46%;
    }

    &__label {
      font-size: 9px;
      color: #808080d4;
      display: flex;

      .icon {
        padding: 0 5px 0 0;
        margin: 0;
        height: 11px;
        display: flex;
        place-content: center;
      }
    }

    &__value {
      text-overflow: ellipsis;
      width: 111px;
      white-space: nowrap;
      overflow: hidden;

      &--status {
        width: auto !important;
      }
    }
  }
}
.header {
  &__nav {
    position: relative;
  }
  &__navbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
  }
  &--icon {
    width: 1.65rem;
    height: 1.65rem;
  }
  &--button {
    top: 0;
    right: 0;
    position: absolute;
    margin: 0;
    padding: 0;
    color: gray;
    cursor: pointer;
    border: 1px solid transparent;
    background-color: transparent;
    &:focus {
      outline: 0;
    }
  }
  &__link {
    display: flex;
    align-items: center;
    .name {
      padding: 0 0 0 5px;
    }
  }

  .vendor-menu {
    margin-top: 5px;
  }
}

.section-header {
  padding-left: 25px;
  padding-top: 5px;
  font-size: 9px;
  opacity: 0.7;
  font-weight: 500;
  cursor: default;
}

.ant-divider {
  margin: 0 0 4px 0px;
}

// Dropdown Menu
.dropdown__menu {
  box-shadow: 0px 0px 10px 0px rgba(160, 160, 170, 0.5);
  top: 100%;
  right: 0;
  position: absolute;
  z-index: 10;
  min-width: 305px;
  margin-top: 1rem;
  overflow-y: auto;
  background-color: white;
  border: 1px solid var(--color-gray);
  background-clip: padding-box;
  .menu--sub-title {
    width: auto !important;
  }
  &-nav {
    padding: 0;
    list-style: none;
    margin-bottom: 5px;
  }
  &-link .menu--description {
    font-size: 10px;
  }
  &-link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    padding: 5px 25px;
    &:hover {
      background-color: #feefea;
      // color: #f37b53;

      .dropdown__menu-text,
      span,
      .link {
        width: 100%;
        font-weight: 600;
        color: $orange !important;
      }
    }

    &.is--active {
      .dropdown__menu-text,
      span,
      a {
        font-weight: 600;
        color: $orange !important;
      }
    }
  }

  &-svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  &-text {
    width: 100%;
    font-size: 13.5px;
    color: #606266;
    display: flex;
    align-items: center;

    a {
      color: #606266;
    }

    svg {
      margin-right: 5px;
      height: 15px;
    }
  }
  &-text.my-apps {
    font-weight: 600;
  }
}
</style>
<template>
  <div>
    <div v-if="isAuthenticated" class="my-profile" @click="handleDropdown()">
      <header class="header">
        <nav class="header__nav">
          <div class="header__navbar">
            <div class="header__item">
              <a class="header__link">
                <!-- Header Navigation Menu Icons -->
                <a-avatar icon="user" />
                <p class="name">
                  {{ userName }}
                </p>
              </a>
              <!-- Dropdown Menu -->
              <transition name="dropdown">
                <div v-if="showDropDown" class="dropdown__menu" :class="{ active: showDropDown }">
                  <div v-if="!isVendorSelected" class="org-menu">
                    <div class="org-details">
                      <p class="section-header p-left-0">Organization Name</p>
                      <div class="org-name">{{ getOrgDetails ? getOrgDetails.name : "---- ----" }}</div>
                      <div class="location-details">
                        <div class="col-1">
                          <label class="location-details__label">
                            <div class="icon">
                              <img width="5" src="@/assets/location-icon.svg" />
                            </div>
                            Current Location
                          </label>
                          <a-tooltip placement="top">
                            <template slot="title">
                              <span>{{ currentLocation }}</span>
                            </template>
                            <p class="location-details__value">{{ currentLocation }}</p>
                          </a-tooltip>
                        </div>
                        <div class="col-2">
                          <label class="location-details__label">Status</label>
                          <p class="location-details__value--status">
                            <template v-if="locationIsActive">
                              <a-badge status="success" /> Active
                            </template>
                            <template v-else>
                              <a-badge status="error" /> Deactivated
                            </template>
                          </p>
                        </div>
                      </div>
                    </div>
                    <a-divider />
                    <!-- <div class="container-page-list">
                      <p v-if="displayPatientChart" class="section-header">My Apps</p>
                      <a-tooltip>
                        <template v-if="getOrgDetails && !getOrgDetails.accountSetup" slot="title">
                          <span>PLEASE FINISH SETTING UP THE ORGANIZATION</span>
                        </template>
                        <ul class="dropdown__menu-nav">
                          <li class="dropdown__menu-item" :class="{ 'is-disabled': !getOrgDetails.accountSetup }">
                            <a
                              class="dropdown__menu-link"
                              :class="{
                                'is--active': getCurrentRoute('marketplace'),
                              }"
                              :disabled="!getOrgDetails.accountSetup"
                              @click="handleMenu('marketplace')"
                            >
                              <div class="dropdown__menu-text my-apps">Marketplace</div>
                              <p class="menu--description">
                                A place to purchase all of the supplies your practice needs get started searching today.
                              </p>
                            </a>
                          </li>
                          <li class="dropdown__menu-item" :class="{ 'is-disabled': !getOrgDetails.accountSetup }">
                            <a
                              :class="{
                                'is--active': getCurrentRoute('practice'),
                              }"
                              class="dropdown__menu-link"
                              :disabled="!getOrgDetails.accountSetup"
                              @click="handleMenu('practice')"
                            >
                              <div class="dropdown__menu-text my-apps">Practice Builder</div>
                              <p class="menu--description">
                                A unique way to have technology tools, forms, and other types of media to market your
                                practice.
                              </p>
                            </a>
                          </li>
                        </ul>
                      </a-tooltip>

                      <a-divider v-if="displayPatientChart" />
                    </div> -->
                    <p v-if="showMyOrgLabel" class="section-header">My Organization</p>
                    <ul class="dropdown__menu-nav">
                      <template v-for="orgMenu in orgMenuList">
                        <li v-if="orgMenu.isVisible" :key="orgMenu.id" class="dropdown__menu-item">
                          <a
                            :class="[
                              orgMenu.customClass,
                              {
                                'is--active': getCurrentRoute(orgMenu.id),
                              },
                            ]"
                            class="dropdown__menu-link"
                            @click="handleMenu(orgMenu.route)"
                          >
                            <div class="dropdown__menu-text">{{ orgMenu.name }}</div>
                          </a>
                        </li>
                      </template>
                    </ul>
                    <!-- <a-divider />
                    <ul class="dropdown__menu-nav">
                      <li v-if="displaySupport" class="dropdown__menu-item">
                        <a
                          :class="{
                            'is--active': getCurrentRoute('support'),
                          }"
                          class="dropdown__menu-link"
                          @click="handleMenu('support')"
                        >
                          <div class="dropdown__menu-text">
                            <font-awesome-icon icon="headset" class="headset-icon" /> Support
                          </div>
                        </a>
                      </li>
                    </ul> -->
                  </div>
                  <template v-else>
                    <div class="vendor-details">
                      <p class="section-header p-left-0">Vendor Name</p>
                      <div class="vendor-name">{{ getVendorName }}</div>
                    </div>
                    <a-divider v-if="getMyRoles.isSimpatraAdmin" />
                    <ul class="dropdown__menu-nav vendor-menu">
                      <!-- <li class="dropdown__menu-item">
                        <a class="dropdown__menu-link" @click="handleMenu('VendorOrders')">
                          <div class="dropdown__menu-text">Dashboard</div>
                        </a>
                      </li> -->
                      <li v-if="getMyRoles.isSimpatraAdmin" class="dropdown__menu-item">
                        <a class="dropdown__menu-link biote-admin" @click="handleMenu('VendorManagement')">
                          <div class="dropdown__menu-text">Biote Admin</div>
                        </a>
                      </li>
                    </ul>
                  </template>
                  <a-divider />
                  <ul class="dropdown__menu-nav">
                    <li class="dropdown__menu-item">
                      <a class="dropdown__menu-link">
                        <div class="dropdown__menu-text cursor-pointer" @click="handleLogout">
                          <font-awesome-icon icon="user" class="headset-icon" />
                          <p>Sign Out</p>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
          </div>
        </nav>
      </header>
    </div>
    <BaseModal v-if="getRequireLocationDialog">
      <BaseCard class="change-location-content">
        <LocationContextChange @locationChanged="toggleLocationSelect" />
      </BaseCard>
    </BaseModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { get, isEmpty } from "lodash";
import BaseModal from "@/components/common/BaseModal.vue";
import BaseCard from "@/components/common/BaseCard.vue";
import LocationContextChange from "@/components/common/LocationContextChange.vue";
import userMe from "@/services/user-me";

export default {
  name: "UserSessionActions",
  components: {
    BaseModal,
    BaseCard,
    LocationContextChange,
  },
  data() {
    return {
      showDropDown: false,
      changeLocationFormActive: false,
      isAuthenticated: false,
    };
  },
  computed: {
    ...mapGetters(["getCurrentUser", "getCurrentLocationName", "getRequireLocationDialog", "getVendorUser", "getOrgId", "getSelectedVendor"]),
    ...mapGetters("locationsModule", ["getAllAvailableLocations", "getCurrentLocationSelected", "getCurrentLocationDetails"]),
    ...mapGetters("accountsModule", ["getOrgDetails"]),
    ...mapGetters("vendorAccountsModule", ["getVendorInfo"]),
    ...mapGetters("usersModule", ["isLoggedIn", "getMyInfo"]),
    getVendorName() {
      return get(this.getVendorInfo, "name", "No Vendor Available");
    },
    userName() {
      const { user } = this.getMyInfo.sim;
      return `${user.firstName} ${user.lastName}`;
    },
    locationIsActive() {
      return get(this.getCurrentLocationDetails, "isActive", false);
    },
    currentLocation() {
      if (this.getMyInfo.sim.user.userType === "ORG") {
        const currentLocation = get(this.getCurrentLocationDetails, "name", "");
        if (!isEmpty(currentLocation)) {
          return currentLocation;
        }
        return "No Location";
      }
      if (this.getVendorUser && this.getMyRoles.isVendorUser) {
        return this.getVendorUser.name;
      }
      return "-";
    },
    showMyOrgLabel() {
      return this.orgMenuList.some(item => item.isVisible);
    },
    isVendorSelected() {
      return this.getSelectedVendor || this.getMyRoles.isVendorUser;
    },
    isEmptyLocation() {
      return isEmpty(this.getAllAvailableLocations);
    },
    hasManyLocations() {
      return this.getAllAvailableLocations.length > 1;
    },
    getMyRoles() {
      return userMe.getMyRoles();
    },
    displayPatientChart() {
      const { isOrgAdmin, isOrgOwner, isSimpatraAdmin } = this.getMyRoles;
      return (isOrgAdmin || isOrgOwner) && isSimpatraAdmin;
    },
    displaySupport() {
      const { isOrgAdmin, isLocationAdmin } = this.getMyRoles;
      return isOrgAdmin || isLocationAdmin;
    },
    orgMenuList() {
      const { isLocationAdmin, isSimpatraAdmin, isConsultant } = this.getMyRoles;
      const showAdminPanel = isLocationAdmin || isSimpatraAdmin;
      return [
        // {
        //   id: "admin",
        //   route: "OrgLocations",
        //   name: "Admin Panel",
        //   isVisible: showAdminPanel,
        // },
        {
          id: "change-location",
          route: "change-location",
          name: "Change Location",
          isVisible: this.hasManyLocations,
        },
        {
          id: "SimpatraConsultations",
          route: "SimpatraConsultations",
          name: "Consultations",
          isVisible: isConsultant,
        },
        {
          id: "VendorManagement",
          route: "VendorManagement",
          name: "Biote Admin",
          isVisible: isSimpatraAdmin,
          customClass: "biote-admin",
        },
      ];
    },
  },
  async mounted() {
    document.addEventListener("click", this.handleClose);
    const { isAuthenticated } = await this.$auth;
    this.isAuthenticated = isAuthenticated;
  },
  methods: {
    ...mapActions(["commitSetRequireLocationDialog", "setAdminStatus", "setCurrentUserTypes"]),
    ...mapActions("publicAPIModule", ["logoutUser"]),
    handleDropdown() {
      this.showDropDown = !this.showDropDown;
    },
    toggleLocationSelect() {
      this.commitSetRequireLocationDialog(!this.getRequireLocationDialog);
    },
    handleClose(e) {
      if (!this.$el.contains(e.target)) {
        this.showDropDown = false;
      }
    },
    handleMenu(command) {
      this.showDropDown = !this.showDropDown;
      if (this.$route.name === command) return;
      if (command === "change-location") {
        localStorage.removeItem("selected_vendor");
        this.toggleLocationSelect();
      } else if (command === "OrgLocations") {
        this.$router.push({ name: "OrgLocations" }).catch(() => {});
      } else if (command === "VendorOrders") {
        const getSelectedVendor = this.getSelectedVendor || this.getVendorInfo.id;
        this.$router.push({
          name: "VendorOrders",
          params: { vendorId: getSelectedVendor },
        });
      } else if (command === "VendorManagement") {
        this.$router.push({ name: "VendorManagement" }).catch(() => {});
      } else if (command === "SimpatraConsultations") {
        this.$router.push({ name: "SimpatraConsultations" }).catch(() => {});
      } else if (command === "support") {
        this.$router.push({ name: "Support" }).catch(() => {});
      } else if (command === "marketplace") {
        this.$router
          .push({
            name: "MarketplaceDashboardHome",
            params: { locationId: this.getCurrentLocationSelected },
          })
          .catch(() => {});
      } else if (command === "patient-chart") {
        this.$router
          .push({
            name: "DosingHome",
            params: { locationId: this.getCurrentLocationSelected },
          })
          .catch(() => {});
      } else if (command === "practice") {
        this.$router
          .push({
            name: "PracticeBuilderDashboard",
            params: { locationId: this.getCurrentLocationSelected },
          })
          .catch(() => {});
      } else if (command === "med-center") {
        this.$router
          .push({
            name: "MedCenterHome",
            params: { locationId: this.getCurrentLocationSelected },
          })
          .catch(() => {});
      }

      if (!this.getCurrentLocationSelected && ["med-center", "practice", "dosing", "marketplace"].includes(command)) {
        this.toggleLocationSelect();
      }
    },
    getCurrentRoute(route) {
      const currentRoute = this.$route.path.split("/");
      if (currentRoute[1] === "support" && currentRoute[1] === route) {
        return true;
      }
      return currentRoute[3] === route || currentRoute[2] === route;
    },
    async handleLogout() {
      await this.logoutUser();
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown__menu-item.is-disabled {
  cursor: not-allowed;
}
.dropdown__menu-item.is-disabled a .dropdown__menu-text {
  color: #b0b0b0;
}

.menu--sub-title {
  font-style: italic;
}
.session-actions {
  display: flex;
  align-items: center;
  // background-color: #495567;
  border-radius: 8px;
  margin-left: auto;
  &:hover {
    cursor: pointer;
  }
}
.session {
  padding-right: 15px;
}
p {
  margin: 0;
}
a {
  text-decoration: none;
}
.icon {
  padding: 0px 10px 0px 15px;
  margin-top: 3px;
}
.name {
  font-size: 12px;
  padding-top: 7px;
}
.session-dropdown {
  width: 250px;
}
.location-select {
  display: flex;
  align-items: center;
}
.location-name-wrapper {
  width: 175px;
}
.location-name {
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  padding-bottom: 7px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.section-label {
  font-size: 10px;
  color: #989898;
  text-transform: uppercase;
  padding-left: 20px;
  padding-bottom: 10px;
  padding-top: 20px;
}
.my-apps {
  .section-label {
    padding-top: 10px;
  }
}
.my-org {
  .section-label {
    margin-top: 10px;
    padding-top: 20px;
    border-top: 1px solid #e6e6e6;
  }
}
.biote-admin,
.simpatra-consultant {
  background-color: $green-20;
}
.change-location-content {
  width: 400px;
}
</style>
